import React from 'react';
import { Link } from 'react-router-dom';
import './footer.css';
import Obfuscate from 'react-obfuscate';
import config from '../../config.json';

function Footer() {
    return (
        <footer className="footer-section">
            <div className="container">
                <div className="row">
                    <div className="row-links">
                        <ul className="header-menu">
                            <li className="header-menu-item mbr-fonts-style display-5">
                                <Link to="/species/gbif/54" className="text-white">Browse</Link>
                            </li>
                            <li className="header-menu-item mbr-fonts-style display-5">
                                <Link to="/species/search" className="text-white">Search</Link>
                            </li>
                            <li className="header-menu-item mbr-fonts-style display-5">
                                <a className="text-white" href="https://www.moulting.org" aria-expanded="false">
                                    Citizen Science
                                </a>
                            </li>
                            <li className="header-menu-item mbr-fonts-style display-5">
                                <Link to="/about" className="text-white">About</Link>
                            </li>
                            <li className="header-menu-item mbr-fonts-style display-5">
                                <Obfuscate email={process.env.REACT_APP_CONTACT_EMAIL}
                                           headers={{subject: '[moultdb] '}}
                                           className="text-white">
                                    Contact
                                </Obfuscate>.
                            </li>
                        </ul>
                    </div>

                    <div className="col-12 mt-4">
                        <div className="social-row">
                            {config.socialMedia.map((item, index) => (
                                <div className="soc-item" key={"fmedia-" + index}>
                                    <a href={item.link} target="_blank" rel="noopener noreferrer">
                                        <span className={"mbr-iconfont socicon display-7 " + item.icon }></span>
                                    </a>
                                </div>
                                ))}
                        </div>
                    </div>

                    <div className="col-12 mt-5">
                        <p className="mbr-fonts-style copyright display-7">

                            Data generated by MoultDB team is marked with&nbsp;
                            <a href="https://creativecommons.org/publicdomain/zero/1.0/" target="_blank"
                               rel="license noopener noreferrer">
                                CC0 1.0
                                <img className="cc_logo"
                                     src="https://mirrors.creativecommons.org/presskit/icons/cc.svg?ref=chooser-v1"/>
                                <img className="cc0_logo"
                                     src="https://mirrors.creativecommons.org/presskit/icons/zero.svg?ref=chooser-v1"/>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;